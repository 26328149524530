// Управление расширенным поиском на СК

app.modules.companySearch = ((self = {}) => {
  const SELECTORS = {
    button: '.js-company-search-form__settings-btn',
    blockWrap: '.js-advanced-search__wrap',
    checkbox: '.js-advanced-search__checkbox',
    radio: '.js-advanced-search__params-radio',
  };

  const CLASSES = {
    buttonActive: 'company-search-form__settings-btn_active',
    hidden: 'advanced-search__hidden',
  };

  function resetParams() {
    document
      .querySelectorAll(`${SELECTORS.radio}, ${SELECTORS.checkbox}`)
      .forEach((radio) => {
        radio.checked = false;
      });
  }

  function onSettingsClick(event) {
    event.target.classList.toggle(CLASSES.buttonActive);
    document
      .querySelector(SELECTORS.blockWrap)
      .classList.toggle(CLASSES.hidden);
    !event.target.classList.contains(CLASSES.buttonActive) && resetParams();
  }

  function onExactSearchChange(event) {
    event.target.value = Number(!!event.target.checked);
  }

  function listener() {
    document
      .querySelector(SELECTORS.button)
      .addEventListener('click', onSettingsClick);
    document
      .querySelector(SELECTORS.checkbox)
      .addEventListener('change', onExactSearchChange);
  }

  self.load = listener;
  return self;
})(app.modules.companySearch);
